import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import ChineseLayout from "../../components/ChineseLayout"
// import Inactivity from "../../components/Inactivity"

/* Basic content page structure */

class Terms extends React.Component {
  componentDidMount() {
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - 法律声明</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -no-content">

          </section>

          <section className="rich-text -bg-white">
            <nav className="breadcrumb" role="navigation">
              <Link to="/zh" title="首页">
                <i className="fas fa-arrow-left"></i>
                首页
              </Link>
            </nav>

            <h1>法律声明</h1>

            <h2>法律声明</h2>

            <h3>一般声明</h3>
            <p>        美赞臣营养品（中国）有限公司，原美赞臣（广州）有限公司，是依照《中华人民共和国公司法》设立并存续的公司。公司位于广州经济技术开发区，严格按照中华人民共和国公司法律规范经营运作。我们声明不担保本网任何内容适合或可供受众在中华人民共和国（台湾地区、香港特别行政区以及澳门特别行政区因适用不同的法律制度，不包括在内，下同）以外地点使用。在某些内容被列为非法的地区，一律禁止读取使用这些材料。在中华人民共和国以外站点访问本网的人士完全出于自愿与个人主动，应该负有遵守适用的当地法律的责任。任何因本网提供内容或服务或因内容或服务本身或因本网公布的规则、政策、指引、声明、条件与条款、协议的任何或所有条款与美赞臣营养品（中国）有限公司发生争议而提出的申诉，适用中华人民共和国实体法律规范（不包括冲突规范）。本法律声明与注册用户协议如有冲突，以本法律声明为准。注册用户协议的特别条件与条款，应与本法律声明一起理解与使用。本法律声明的任何或所有条款均是注册用户协议不可分割的组成部分。对以上条款，其解释权在美赞臣营养品（中国）有限公司；对以上条款的变更、修改，请用户自行查看相关内容，本站不负另行通知的义务。</p>
            
            <p>        美赞臣营养品（中国）有限公司在此声明，在本网站上发布的任何信息不得视为美赞臣营养品（中国）有限公司就销售、处分、贸易或交易等作出的要约邀请。任何投资者不可依赖上述信息作出投资决定。</p>

            <p>        美赞臣营养品（中国）有限公司对于为任何目的而在网站上发布的文件及有关图形所含信息的适用性，不作任何声明。所有该等文件及有关图形均“依样”提供，而不带任何性质的保证。</p>

            <p>        美赞臣营养品（中国）有限公司特此声明，对所有与该等信息有关的保证和条件不负任何责任，该等保证和条件包括关于适销性、符合特定用途、所有权和非侵权的所有默视保证和条件。在任何情况下，在由于使用或运用本网站上的信息所引起的或与该等使用或运行有关的诉讼中，美赞臣营养品（中国）有限公司就因丧失使用、数据或利润所导致的任何特别的、间接的或衍生性的损失或任何种类的损失，均不负任何责任，无论该等诉讼是合同之诉、疏忽或其它侵权行为之诉。</p>

            <p>        本网站发布的文件和有关图形可能会有技术误差或打印错误，将定期对信息予以订正。美赞臣营养品（中国）有限公司可能会在任何时间对本法律声明所描述的产品和/或程序作出改进和/或订正。</p>

            <h3>关于本网站软件、文件及服务的使用、限制</h3>

            <p>        使用本网站的文件，包括但不限于新闻稿、数据表、说明书和常用问答集</p>
            
            <p>        （FAQ）（统称“文件”）的许可已经授予，但必须遵守下列规定：（1）下面的著作权标志出现在所有副本上；（2）使用本网站的文件，仅限于为信息和非商业或个人之目的使用；及（3）不得对任何文件作任何修改。为任何其他目的之使用，均被法律明确禁止，并可导致严重的民事及刑事处罚。违反者将在可能的最大程度上受到指控。（上述文件不包括<a href="www.meadjohnson.com.cn" target="_blank" rel="noopener noreferrer">www.meadjohnson.com.cn</a>这一网站或任何其它美赞臣营养品（中国）有限公司所拥有、管理、许可或控制的网站的设计或布局）</p>
            
            <p>        在任何情况下，在由于使用或运行软件、文件、或使用本网站上的信息、提供或未能提供的服务而引起的或与之有关的任何诉讼，美赞臣营养品（中国）有限公司对因丧失使用的数据或利润所导致的特别的、间接的、衍生性的损失或任何种类的损失，均不负任何责任，无论该等诉讼是合同之诉、疏忽或其它侵权行为之诉。</p>

            <p>        除本网注明之服务条款外，任何因使用本网或与本网连接的任何其他网站或网页而引致的意外、疏忽、合约毁坏、诽谤、感染电脑病毒、版权或其他知识产权纠纷及其造成的损失，美赞臣营养品（中国）有限公司概不负责，不承担任何法律责任。</p>

            <h3>链接到第三方网站</h3>

            <p>        在本区域的链接会使您离开美赞臣营养品（中国）有限公司的网站。由于所链接地网站不在美赞臣营养品（中国）有限公司的控制范围内，所以，对于任何所链接的网站上的内容或某一链接地网站所含的任何链接或对该等网站的任何变动或更新，美赞臣营养品（中国）有限公司不负责任。美赞臣营养品（中国）有限公司对从所链接的网站收到的网络传送或任何其它形式的传输不承担任何责任。美赞臣营养品（中国）有限公司向您提供这些链接仅为了提供方便，带有任何链接并非默示美赞臣营养品（中国）有限公司对该网站的认可。</p>

            <h3>专家问答、论坛管理规定</h3>

            <p>        您在专家问答或论坛发表的观点均不代表本网论坛以及美赞臣营养品（中国）有限公司立场，您需要文责自负。您所发布信息版权归您本人所有。您转贴时应注明原始出处和时间，并负担由此可能产生的版权责任；论坛根据版面管理需要有权删除转帖。</p>

            <p>        任何转载、引用发表于本论坛或专家问答的版权文章、图片及其它作品须符合以下规范：1.用于非商业、非盈利、非广告性目的时需注明作者及出处为美赞臣营养品（中国）有限公司网站；2.用于商业、盈利、广告性目的时需征得文章、图片以及其它作品的原作者的同意，不得未经作者同意而剽窃、引用本论坛或专家的任何版权作品。3.任何文章、图片以及其它作品的修改或删除均应保持作者原意并征求原作者同意，并注明授权范围。</p>

            <p>        您使用本网论坛或专家问答服务所存在的风险明确同意由您本人承担；您使用本网论坛或专家问答而产生的一切后果也由您本人承担，美赞臣营养品（中国）有限公司网站对您不承担任何责任。我们不担保论坛或专家问答服务一定能满足您的要求，也不担保论坛或专家问答服务不会中断，对论坛或专家问答服务的及时性、安全性、准确性也都不作担保。我们不保证为向您提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由我们实际控制的任何网页上的内容，论坛不承担任何责任。对于因不可抗力或论坛不能控制的原因造成的服务中断或其它缺陷，我们不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>

            <h3>商标</h3>

            <p>        本网站提及的所有商标，包括但不限于美赞臣营养品（中国）有限公司和/或其它文件中提及的美赞臣营养品（中国）有限公司产品，是美赞臣营养品（中国）有限公司的商标或注册商标，受任何和所有的适用法律和法规的保护。</p>

            <h3>其它权利</h3>

            <p>        本网站的组成部分受有关装潢、商标、著作权、不正当竞争法律及其它法律的保护，不得作全部或部分复制或仿造。除非经美赞臣营养品（中国）有限公司明确许可，不得复制或再传送任何本网站上的任何标识、图形、声音或图象。  登录者必须严格遵守《维护互联网安全的决定》等规定，不得发布任何反动信息；不得侵害他人名誉权、隐私权、姓名权、肖像权；不得侵犯他人著作权等。</p>

            <p>        未经本法律声明明确授予的任何权利均予保留。</p>

            <p>        如有任何疑问或问题，请与我们联系。联系电话020-38111188，邮寄地址“广州市天河区林和中路8号海航大厦28楼美赞臣营养品（中国）有限公司”。</p>

          </section>
        </main>
      </ChineseLayout>
    )
  }
} 

export default Terms